/**
 * Represents locations that have charge points
 */
export interface ILocations {
  locations: ILocation[];
}

/**
 * Represents a location that has charge points
 */
export interface ILocation {
  /**
   * The location id
   */
  id: number;

  /**
   * The location name
   */
  name: string;

  /**
   * The latitude and longitude of the location
   */
  geoPosition: GeoPosition;

  /**
   * The location post code
   */
  postCode: string;

  /**
   * The status of the location:
   * - available (Some charge points are available)
   * - in-use (All charge points are in use)
   * - out-of-service (All charge points are out of service)
   */
  status: LocationStatus;

  /**
   * The date and time the location was last updated (ISO-8601)
   * e.g. 2022-04-17T14:48:00Z
   */
  lastUpdated: string;

  /**
   * Indicates if the location is public
   */
  public: boolean;

  /**
   * Details of the charge points at the location
   */
  chargePoints: IChargePoint[];
}

/**
 * The latitude and longitude of a geographic position
 */
export interface GeoPosition {
  latitude: number;
  longitude: number;
}

/**
 * Represents a charge point with EVSEs
 */
export interface IChargePoint {
  /**
   * The status of the charge point:
   * - available (Some EVSEs are available)
   * - in-use (All EVSEs are in use)
   * - out-of-service (All EVSEs are out of service)
   */
  status: Status;

  /**
   * Details of the EVSEs in the charge point
   */
  evses: IEvse[] | undefined;
}

/**
 * Represents an EVSE with connectors
 */
export interface IEvse {
  /**
   * The three word ID for the QR code, e.g. "frog-bell-tree"
   */
  id: string;

  /**
   * The current type:
   * - ac
   * - dc
   */
  currentType: CurrentType;

  /**
   * The max power in Wh
   */
  maxPower: number;

  /**
   * The status of the EVSE:
   * - available (All connectors are available)
   * - in-use (One of the connectors is in use)
   * - out-of-service (All connectors are out of service)
   */
  status: Status;

  /**
   * Details of the connectors in the EVSE
   */
  connectors: IConnector[] | undefined;

  pricePerKwh: number | null;

  dayPricePerKwh?: number | null;

  nightPricePerKwh?: number | null;

  idleFeePerMinute?: number | null;

  dayIdleFeePerMinute?: number | null;

  nightIdleFeePerMinute?: number | null;
}

/**
 * Represents a connector
 */
export interface IConnector {
  /**
   * The connector type:
   * - type1
   * - type2
   * - type3
   * - chademo
   * - ccs1
   * - ccs2
   * - catarc
   * - schuko
   * - supercharger
   * - cee16
   * - cee32
   * - j1772
   * - inductive
   * - nema-5-20
   * - type-e-french
   * - type-g-british
   * - type-j-swiss
   * - avcon
   */
  type: ConnectorType;

  /**
   * The status of the connector:
   * - available (The connector is available)
   * - in-use (The connector is in use)
   * - out-of-service (The connector is out of service)
   */
  status: Status;

  /**
   * The format of the connector, whether it is socket or cable
   */
  format: ConnectorFormat;
}

/**
 * The current type
 */
export enum CurrentType {
  AC = "ac",
  DC = "dc",
}

/**
 * The connector type
 */
export enum ConnectorType {
  Type1 = "type1",
  Type2 = "type2",
  Type3 = "type3",
  CHAdeMO = "chademo",
  CCS1 = "ccs1",
  CCS2 = "ccs2",
  CATARC = "catarc",
  Schuko = "schuko",
  Supercharger = "supercharger",
  CEE16 = "cee16",
  CEE32 = "cee32",
  J1772 = "j1772",
  Inductive = "inductive",
  Nema520 = "nema-5-20",
  TypeEFrench = "type-e-french",
  TypeGBritish = "type-g-british",
  TypeJSwiss = "type-j-swiss",
  AVCON = "avcon",
}

/**
 * The status of a location
 */
export enum LocationStatus {
  Available = "available",
  InUse = "in-use",
  OutOfService = "out-of-service",
  LessThanHalfInUse = "less-than-half-in-use",
  BetweenHalfAndThreeQuartersInUse = "between-half-and-three-quarters-in-use",
  MoreThanThreeQuartersInUse = "more-than-three-quarters-in-use",
}

/**The status of a charge point, EVSE or connector
 */
export enum Status {
  Available = "available",
  InUse = "in-use",
  OutOfService = "out-of-service",
  Disabled = "disabled",
}
/**
 * The format of the connector: socket or cable
 */
export enum ConnectorFormat {
  Socket = "socket",
  Cable = "cable",
}
